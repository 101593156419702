import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { iparhaiServer } from '../../store/services/config/api.config';

const AssigmentAccordion = ({ no, id, question, pdfUrl, totalMarks, grade }) => {
    const [loading, setLoading] = useState(false);
    const [newGrade, setNewGrade] = useState(grade); // Initialize with grade to avoid additional checks

    const handleAutograde = async () => {
        setLoading(true); // Start loader
        try {
            const { data } = await iparhaiServer.get(`/api/question/aws/gradeAssignment/${id}`);
            if (data) {
                setNewGrade(data); // Update grade if new grade data exists
            }
        } catch (error) {
            console.log(error.response || error.message); // Handle errors appropriately
        } finally {
            setLoading(false); // Stop loader
        }
    };

    return (
        <Accordion key={no}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <div className="w-full flex justify-between">
                    <Typography>
                        <span className="font-semibold">QUESTION No. {no + 1}:</span>{" "} {/* No need for ++no */}
                        {question}
                    </Typography>
                    <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg ">
                        <span className="font-medium">Total Marks:</span>{" "}
                        <span className="text-[#004e8f] font-semibold">{totalMarks}</span>
                    </p>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    sx={{
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        hyphens: "auto",
                    }}
                    className="max-w-[95%]"
                >
                    <span className="font-semibold">Assignment:</span>
                </Typography>
                <div className="flex justify-end">
                    <Button
                        variant="contained"
                        onClick={handleAutograde}
                        color={newGrade ? 'success' : 'primary'}
                        disabled={loading} // Disable button when loading
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" /> // Show loader
                        ) : (
                            newGrade ? 'Auto Graded' : 'Auto Grading'
                        )}
                    </Button>
                </div>
                <div className="h-[700px] flex justify-center">
                    <iframe
                        src={pdfUrl}
                        width="70%"
                        height="100%"
                        title="PDF Preview"
                        style={{ border: 'none' }} // Removes border
                    ></iframe>
                </div>
                <div className="border mt-4">
                    <p className="text-xl font-semibold">
                        GRADE: <span className="text-sm">{newGrade}</span>
                    </p>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AssigmentAccordion;
