import ReCAPTCHA from "react-google-recaptcha";

import {
  faAt,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import Authservice from "../../store/services/auth.service";
import jwt_decode from "jwt-decode";
import Modal from "@mui/material/Modal";
import auth from "../../store/actions/auth";
import user from "../../store/actions/user";
import authService from "../../store/services/auth.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Userauth from "../../store/services/auth.service";
import { useEffect } from "react";
import { useRef } from "react";
import { setCompanyId } from "../../store/actions/newMessage";
import { Circles } from "react-loader-spinner";
import axios from "axios";
import { iparhaiServer } from "../../store/services/config/api.config";

const style = {
  width: { xs: "70%", sm: "40%", md: "30%", xl: "35%" },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#B0C8DC",
  border: "2px solid #004E8F",
  borderRadius: "10px",
  p: 4,
};

function Login() {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [modalContent, setModalContent] = useState({ heading: "", body: "" });
  const [loadingResendOTP, setLoadingResendOTP] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [otpmodal, setotpModal] = useState(false);
  const [verifyotploading, setverifyotpLoading] = useState(false);
  const [forgetPasswordAlert, setForgetPasswordAlert] = useState({
    status: "",
    message: "",
  });
  const [apiResponse, setapiResponse] = useState([false, ""]);
  const [resendOTP, setResendOTP] = useState(false);
  const [modal, setModal] = useState(false);
  const [display, setDisplay] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const captchaRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState("");

  let multipleDevices = localStorage.getItem("multipleDevices");
  // useEffect(()=>{
  //   if(multipleDevices){
  //     console.log('running on login')
  //     setModalContent({heading:'Session Expired!', body:'You can only login from one device at a time.'})
  //     setLoading(false);
  //     setBlockModal(true);
  //     setLoadingResendOTP(false);
  //     // setRemainingTime(10);
  //     setTimeout(() => {
  //       setBlockModal(false);
  //     }, 5000);
  //     localStorage.removeItem('multipleDevices')
  //     return;

  //   }
  // },[])

  useEffect(() => {
    if (multipleDevices) {
      setModalContent({
        heading: "Session Expired!",
        body: "You can only login from one device at a time.",
      });
      setLoading(false);
      setBlockModal(true);
      setLoadingResendOTP(false);
      // setRemainingTime(10);
      setTimeout(() => {
        localStorage.removeItem("multipleDevices");
        setBlockModal(false);
      }, 5000);
    }
    setRemainingTime(60);
    startTimer();
    const delay = setTimeout(() => {
      setDisplay(true);
    }, 2000); // 2 seconds delay
  }, [otpmodal]);

  const openModal = () => {
    setverifyLoading(false);
    setapiResponse([false, ""]);
    setVerifyEmailError(false);
    setModal(true);
    setInputs((prevState) => ({
      ...prevState,
      emailVerify: "",
    }));
  };
  const closeModal = () => setModal(false);
  const validateEmailAndPassword = () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^[a-zA-Z0-9!@^()_+\\[\]\\|<>]*$/;

    // if (!emailRegex.test(inputs.email)) {
    //   setError({
    //     isError: true,
    //     errorMessage: "Please enter a valid email address.",
    //   });
    //   return false;
    // }

    if (!passwordRegex.test(inputs.password)) {
      setError({
        isError: true,
        errorMessage: "Password must not special characters.",
      });
      return false;
    }

    return true;
  };
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    emailVerify: "",
  });

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [verifyEmailError, setVerifyEmailError] = useState(false);
  const [vissible, setVissible] = useState(false);
  const [FAVAlue, setFAValue] = useState("");
  const [loginRes, setLoginRes] = useState(null);
  const [open2FAModal, setOpen2FAModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyloading, setverifyLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

  function handleOnChange(e) {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  const handleOnChangeFA = (e) => {
    setFAValue(e.target.value);
  };

  const verify2FA = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      // Determine whether to send the OTP code or the backup code based on the length
      const isBackupCode = FAVAlue.length > 6;

      // Prepare the request payload
      const payload = {
        code: isBackupCode ? undefined : FAVAlue,
        backupCode: isBackupCode ? FAVAlue : undefined,
        email: inputs.email,
      };

      // Make the API request
      const res = await iparhaiServer.post("/api/userAuth/verify2FA", payload);

      if (res.status === 200) {
        // Handle successful response
        localStorage.setItem("token", loginRes.token);
        localStorage.setItem("email", inputs.email);
        dispatch(setCompanyId(loginRes.companyId));
        localStorage.setItem("cid", loginRes.companyId);
        localStorage.setItem("co", loginRes.companyCode);
        navigate("/dashboard");
      } else {
        alert(res.data);
      }
    } catch (error) {
      console.log(error.response);
      setFAValue("");
      alert(error.response.data.message);
    }
  };
  const handleGetBackupCodes = async () => {
    try {
      const res = await iparhaiServer.post("/api/userAuth/getBackupCodes", {
        email:inputs.email,
      });
      alert('Backup codes have been successfully sent to your email.')
    } catch (error) {
      console.log(error.response);
    }
  };

  async function handleLogin(e) {
    e.preventDefault();
    setEmailError(false);
    setPasswordError(false);
    setError({
      isError: false,
      errorMessage: "",
    });

    if (
      // emailRegex.test(inputs.email) &&
      inputs.password.length >= 0 &&
      recaptchaValue !== null
    ) {
      try {
        if (!validateEmailAndPassword()) {
          return;
        }
        setLoading(true);
        captchaRef.current.reset();
        const res = await authService.login(
          inputs.email,
          inputs.password,
          recaptchaValue
        );
        if (res.status === 400) {
          setError({
            isError: true,
            errorMessage: res.data.error,
          });
        }
        if (res.status === 403) {
          setModalContent({
            heading: "Account Blocked!",
            body: "Your account is temporary blocked. Contact Us. \n proctorparhai@syslab.ai",
          });
          setLoading(false);
          setBlockModal(true);
          setLoadingResendOTP(false);
          // setRemainingTime(10);
          setTimeout(() => {
            setBlockModal(false);
          }, 5000);
          return;
        }

        const companyid = res.data.companyId;
        setLoginRes(res.data);
        // console.log(companyid)
        if (!jwt_decode(res.data.token).isVerified) {
          setToken(res.data.token);
          await handleResendOtp()
          openotpModal();
          setLoading(false);
          setToken(res.data.token);
        } else if (res.data.twoFA) {
          setLoading(false);
          setOpen2FAModal(true);
        } else {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("email", inputs.email);
          dispatch(setCompanyId(companyid));
          localStorage.setItem("cid", companyid);
          localStorage.setItem("co", res.data.companyCode);
          if (
            localStorage.getItem("testCode") &&
            jwt_decode(res.data.token).role === 1
          ) {
            console.log('runnnnnnn')
            navigate(`/dashboard?code=${localStorage.getItem("testCode")}`);
          } else {
            console.log('else')
            if (res.data.twoFA) {
              setOpen2FAModal(true);
            }
            localStorage.removeItem("testCode");
            navigate("/dashboard");
          }
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setPasswordError(!(inputs.password.length >= 0));
      setError({
        isError: true,
        errorMessage: "Please complete the CAPTCHA",
      });
    }
  }

  const [otperror, setotpError] = useState({
    isError: false,
    errorMessage: "",
  });

  const startTimer = () => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        }
        if (!otpmodal) {
          clearInterval(timer);

          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return;
  };

  const handleResendOtp = async () => {
    try {
      setLoadingResendOTP(true);
      const res = await authService.resendOTP(inputs.email);
      // console.log(res);
      setRemainingTime(60);
      startTimer();
      // if (res.status === 403) {
      //   setBlockModal(true);
      //   setLoadingResendOTP(false);
      //   // setRemainingTime(10);
      //   setTimeout(() => {
      //     setBlockModal(false);
      //   }, 3000);
      //   return;
      // }
      setLoadingResendOTP(false);
    } catch (error) {
      setLoadingResendOTP(false);
      console.log(error);
    }
  };

  const openotpModal = () => {
    setotpModal(true);
    setInputs((prevState) => ({
      ...prevState,
      otp: "",
    }));
  };
  const closeotpModal = () => setotpModal(false);
  const close2FAModal = () => setOpen2FAModal(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setotpError([false, ""]);
    setverifyotpLoading(true);
    if (inputs.otp.length > 6) {
      setverifyotpLoading(false);
      setotpError([true, "OTP should not be greater than 6 digits"]);
      return;
    }
    if (inputs.otp.length === 0) {
      setverifyotpLoading(false);
      setotpError([true, "Enter OTP"]);
      return;
    }
    try {
      const response = await Authservice.verifyotp(inputs.email, inputs.otp);
      if (localStorage.getItem("token")) {
        setverifyotpLoading(false);
        closeotpModal();
        setotpError([false, "success"]);
        navigate("/dashboard");
      } else {
        localStorage.setItem("token", token);
        setverifyotpLoading(false);
        localStorage.setItem("token", token);
        setotpError([false, "success"]);
        alert("user verified");
        navigate("/dashboard");
      }
      setotpError([false, ""]);
    } catch (error) {
      setverifyotpLoading(false);
      setotpError([true, "Error Wrong Otp or User already verified"]);
    }
  };
  const sendFPLink = async () => {
    try {
      // setEmailSendLoading(true)
      const res = await iparhaiServer.post(
        "/api/userAuth/sendForgetPasswordLink",
        { email: inputs.emailVerify }
      );
      setForgetPasswordAlert({ status: 200, message: res.data.message });
      // console.log(res)
    } catch (error) {
      setForgetPasswordAlert({ status: 500, message: "Try Again." });
      // console.log(error.response)
    }
  };

  return (
    <>
      {display ? (
        <Box sx={{ mt: 5, position: "relative" }}>
          <form>
            {(emailError || passwordError || error.isError) && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {emailError
                  ? "Please enter a valid email address."
                  : passwordError
                  ? "Please Enter password."
                  : error.errorMessage}
              </Alert>
            )}
            <Typography fontWeight={800} sx={{ mb: 1 }}>
              Email / Username
            </Typography>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                id="email"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Your Email / Username"
                value={inputs.email}
                onChange={handleOnChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin(e);
                  }
                }}
              />
              <AlternateEmailIcon sx={{ color: "#004E8F" }} />
            </Paper>

            <Typography
              fontWeight={800}
              sx={{ mb: 1, mt: 2, userSelect: "none" }}
            >
              Password
            </Typography>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                id="password"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Your Password"
                type={vissible ? "text" : "password"}
                value={inputs.password}
                onChange={handleOnChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin(e);
                  }
                }}
              />
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setVissible((prevState) => !prevState);
                }}
                color="#004E8F"
                icon={vissible ? faEye : faEyeSlash}
              />
            </Paper>
            <Box
              sx={{
                mb: 5,
              }}
            >
              {" "}
              <Button
                onClick={openModal}
                variant="text"
                sx={{ position: "absolute", right: "1%" }}
              >
                Forget Password?
              </Button>
              <Modal
                open={modal}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {verifyEmailError && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {"Please enter a valid email address."}
                    </Alert>
                  )}
                  {forgetPasswordAlert.status && (
                    <Alert
                      sx={{ mb: 2 }}
                      severity={
                        forgetPasswordAlert.status === 200 ? "success" : "error"
                      }
                    >
                      {forgetPasswordAlert.message}
                    </Alert>
                  )}
                  <Paper
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <InputBase
                      id="emailVerify"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Enter Your Email"
                      value={inputs.emailVerify}
                      onChange={handleOnChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sendFPLink();
                        }
                      }}
                    />
                    <FontAwesomeIcon color="#004E8F" icon={faAt} />
                  </Paper>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", mt: 5, ml: 0.5, mb: 2 }}
                    color="primary"
                    onClick={sendFPLink}
                    disabled={verifyloading}
                    id="verify"
                  >
                    {verifyloading ? "Sending Email" : "Send Reset Link"}
                    {verifyloading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        style={{ marginLeft: "8px" }}
                      />
                    )}
                  </Button>
                </Box>
              </Modal>
            </Box>

            <Paper
              sx={{
                mt: 2,
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <ReCAPTCHA
                ref={captchaRef}
                // sitekey="6LeTDKApAAAAAJhkdpEOte3a67_7gxcx5C60IJG8" //local\
                sitekey="6LeZoawpAAAAAEiNQ3KvuNBH0jTYCnX67lRuq8RN" //for live
                onChange={(value) => setRecaptchaValue(value)}
              />
            </Paper>
          </form>

          <Button
            variant="contained"
            sx={{ width: "100%", mt: 10, ml: 0.5, mb: 2 }}
            color="primary"
            onClick={handleLogin}
            id="submit"
            disabled={loading}
          >
            {loading ? "Logging in" : "Log in"}
            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: "8px" }}
              />
            )}
          </Button>

          {/* OTP VERIFY MODAL */}
          <Modal
            open={otpmodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                style={{
                  marginBottom: "10px",
                  textAlign: "left",
                  lineHeight: "1.25",
                  fontWeight: "bold",
                }}
              >
                Verify your Email first to login, OTP has been sent to your
                email. If you didn't receive please check Spam in your mail.
                <br />
                <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                  {Math.floor(remainingTime / 60)}:
                  {remainingTime % 60 < 10 ? "0" : ""}
                  {remainingTime % 60}
                </div>
              </Typography>
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  id="otp"
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter Your OTP"
                  value={inputs.otp}
                  onChange={handleOnChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                />
              </Paper>
              <Button
                variant="contained"
                sx={{ width: "100%", mt: 5, ml: 0.5, mb: 2 }}
                color="primary"
                onClick={handleSubmit}
                disabled={verifyotploading}
                // id="verify"
              >
                {verifyotploading ? "" : "Verify OTP"}
                {verifyotploading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </Button>
              <Button
                onClick={closeotpModal}
                disabled={verifyotploading || otperror[1] === "success"}
                variant="contained"
                color="error"
                type="submit"
              >
                {/* <FontAwesomeIcon icon={faBackward} /> */}
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: "10px", height: "40px", width: "120px" }}
                onClick={handleResendOtp}
                disabled={remainingTime > 0}
                variant="contained"
                color="primary"
                type="submit"
              >
                {/* <FontAwesomeIcon icon={faBackward} /> */}
                {loadingResendOTP ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: "8px" }}
                  />
                ) : (
                  "Resend OTP"
                )}
              </Button>
            </Box>
          </Modal>
          {/* TWO FACTOR AUTHENTICATION MODAL */}
          <Modal
            open={open2FAModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="focus:outline-none" sx={style}>
              <div className="mb-4">
                <h3 className="text-center text-2xl md:text-3xl">
                  Multi-factor Authentication
                </h3>
                <p className="text-center">
                  Enter an 2FA code to complete log-in.
                </p>
              </div>
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  id="otp"
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter Your 2FA code"
                  value={FAVAlue}
                  onChange={handleOnChangeFA}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      verify2FA(e);
                    }
                  }}
                />
              </Paper>
              <p className="mt-2 text-right">
                <span
                  onClick={handleGetBackupCodes}
                  className="font-medium text-gray-500 cursor-pointer hover:text-black"
                >
                  send backup codes?
                </span>
              </p>
              <Button
                variant="contained"
                sx={{ width: "100%", mt: 5, ml: 0.5, mb: 2 }}
                color="primary"
                onClick={verify2FA}
                disabled={verifyotploading}
                // id="verify"
              >
                {verifyotploading ? "" : "Submit"}
                {verifyotploading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </Button>
              <Button
                onClick={close2FAModal}
                disabled={verifyotploading || otperror[1] === "success"}
                variant="contained"
                color="error"
                type="submit"
              >
                {/* <FontAwesomeIcon icon={faBackward} /> */}
                Cancel
              </Button>
            </Box>
          </Modal>

          <Divider>OR</Divider>
          <Button
            variant="contained"
            sx={{ width: "100%", mt: 2, ml: 0.5, mb: 2 }}
            color="secondary"
            onClick={() => {
              navigate("/Signup");
            }}
            disabled={loading}
          >
            Sign up
          </Button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={blockModal}
            // onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={blockModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {modalContent.heading}
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  {modalContent.body}
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </Box>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "540px",
            }}
          >
            <Circles
              height="80"
              width="80"
              color="#004e8f"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Login;
